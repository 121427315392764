
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































































































.g-share {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 2px solid $c-black-20;

  @include mq(s) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.g-share__label {
  flex-basis: 100%;
  margin-bottom: $spacing * 0.7;
  color: rgba($c-black, 0.6);
  text-align: center;

  @include mq(s) {
    flex-basis: auto;
    margin: 0 $spacing * 1.5 0 0;
    text-align: left;
  }
}

.g-share__list {
  @extend %list-nostyle;

  position: relative;
  display: flex;
}

.g-share__item {
  & + & {
    padding-left: $spacing * 0.5;
  }

  @include mq(s) {
    position: relative;
  }
}

.g-share__item__tooltip {
  @include center-x;

  bottom: -$spacing * 2;
  padding: 0 $spacing;
  white-space: nowrap;
  border: 1px solid $c-black-60;
  border-radius: 2rem;
  transition: 0.25s ease-out;

  @include mq(s) {
    @include center-y;

    right: -$spacing;
    bottom: initial;
    left: initial;
    transform: translate(100%, -50%);
  }
}

.tooltip-enter,
.tooltip-leave-to {
  bottom: -$spacing * 4;
  opacity: 0;

  @include mq(s) {
    transform: translate(100%, -40%);
  }
}

.tooltip-leave-to {
  bottom: -$spacing;

  @include mq(s) {
    transform: translate(100%, -60%);
  }
}
