
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































.g-content-outer {
  position: relative;
  z-index: 1;
}

.g-content__top {
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: $c-white;
  }

  &::after {
    top: initial;
    bottom: 0;
    width: 100%;
    transition: background 0.5s;
  }

  .is-visible &::after {
    background: $c-black-10;
  }

  @include mq($until: m) {
    .agenda &::after {
      background: $c-white;
    }
  }
}

.g-content__main {
  padding: $spacing * 2 0 $spacing * 4.5;
  background: $c-white;
  border-radius: 0 0 5rem 5rem;
  transition: background 0.5s;

  .is-visible & {
    background: $c-black-10;
  }

  @include mq(m) {
    padding-top: $spacing * 4.5;
    border-radius: 0 0 10rem 10rem;
  }
}

.g-content__newsletter {
  position: sticky;
  z-index: -2;
  bottom: 0;
}
