
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.g-load-more {
  margin-top: $spacing * 4.5;
}

.g-load-more__label {
  margin-bottom: $spacing * 1.5;
}
