
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































.g-newsletter,
[class*='g-newsletter--'] {
  padding-top: $spacing * 3.5;
  padding-bottom: $spacing * 3.5;

  @include mq(l) {
    padding-top: $spacing * 7;
    padding-bottom: $spacing * 4.5;
  }
}

.g-newsletter__form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing;

  @include mq(s) {
    width: 50rem;
    margin: $spacing auto 0;
  }

  @include mq(m) {
    width: col(8, 18);
    margin: $spacing * 3 auto 0;
  }
}

.g-newsletter__input {
  width: 100%;
  margin: 0 auto;

  ::v-deep {
    .form-feedback {
      @include center-x;

      bottom: -3rem;
      opacity: 1;
    }
  }
}

.g-newsletter__button {
  &[class*='action--'][class*='--btn'] {
    @include center-y;

    right: 0.6rem;
    box-shadow: none;

    &:hover,
    &:focus-visible {
      background: $c-white;
    }
  }

  @include mq($until: m) {
    &[class*='action--'][class*='--btn'] {
      padding: $spacing * 0.7;
    }

    ::v-deep {
      .action__label {
        @include visually-hidden;
      }

      .action__icon-wrapper {
        margin: 0;
      }
    }
  }

  @include mq(m) {
    ::v-deep {
      .action__icon-wrapper {
        display: none;
      }
    }
  }
}

.g-newsletter__form__confirmation {
  @include center-x;

  margin-top: $spacing;
}

.form-confirmation__feedback {
  display: flex;
  align-items: center;
  color: $c-black-80;

  .icon {
    margin-right: $spacing;
    color: $c-blue-80;
  }
}
