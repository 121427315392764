
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































$percentage-width: 40rem;

.g-main-title,
.g-baseline {
  max-width: none;
}

.about-keynumbers__percentages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: max(4rem, calc(100% - calc(#{$percentage-width} * 2)));
  row-gap: 12vw;

  @include mq(l) {
    justify-content: center;
  }
}

.about-percentages {
  flex: $percentage-width 1 1;

  @include mq(l) {
    display: flex;
    flex-direction: column;
    flex-grow: 0;

    ::v-deep .about-percentage__label {
      overflow: visible;
    }
  }
}
