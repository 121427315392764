
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.flexible-wysiwyg-title__title {
  @include fluid(
    margin-bottom,
    (
      xxs: 20px,
      l: 48px,
    )
  );

  text-align: center;
}
