
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































[class*='dev-grid--'],
.dev-grid {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
  display: none;
  opacity: 1;
  pointer-events: none;
}

.dev-grid-inner {
  display: flex;
  height: 100%;
}

.dev-grid__grid {
  position: relative;
  width: col(1);

  &::before {
    @include get-all-space;

    content: '';
    border-left: 1px solid rgba($c-black, 0.1);
  }

  &:nth-child(3n + 1)::before {
    border-left: 1px solid rgba($c-black, 0.4);
  }

  &:last-child::before {
    border-right: 1px solid rgba($c-black, 0.8);
  }

  &:nth-child(6n + 1)::before {
    border-left: 1px solid rgba($c-black, 0.8);
  }
}

.dev-grid__grid__label {
  @extend %fw-bold;
  @include center-x;

  bottom: 40px;
  color: $c-gray-medium;
  font-size: 1.1rem;
  line-height: 1em;
  white-space: nowrap;

  .dev-grid__grid:nth-child(3n + 2) & {
    bottom: 60px;
  }

  @include mq(m) {
    font-size: 1.6rem;
  }
}
