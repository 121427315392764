
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  &.inline {
    display: inline-flex;
    vertical-align: middle;
    padding: 0 0 0 1rem;
  }
}
