
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































.g-main-title,
.g-baseline {
  max-width: none;
}

.about-team__switcher {
  margin: 0 col(1, 20);
  padding: $spacing * 0.5;
  background: $c-white;
  border-radius: 1rem;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.06);

  ::v-deep {
    .form-switcher {
      width: 100%;
    }
  }

  @include mq(m) {
    margin: 0 col(1, 18);
    padding: $spacing * 1.2 $spacing * 2;

    ::v-deep {
      .form-switcher {
        width: auto;
      }

      .form-switcher__left,
      .form-switcher__right {
        width: 30vw;
        max-width: 27rem;
      }
    }
  }

  @include mq(l) {
    width: fit-content;
    margin: 0 auto;
  }
}

.about-team__heading,
.about-team__grid {
  padding-right: col(1, 20);
  padding-left: col(1, 20);

  @include mq(m) {
    padding-right: col(1, 18);
    padding-left: col(1, 18);
  }

  @include mq(l) {
    padding-right: col(1.5, 19);
    padding-left: col(1.5, 19);
  }
}

::v-deep {
  .g-content__main {
    border-radius: 0 0 4rem 4rem;
  }
}
