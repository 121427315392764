
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.team-popup,
[class*='team-popup--'] {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 90vh;
  text-align: left;
  border-radius: 2rem;

  @include mq(s) {
    flex-direction: row;
  }

  @include mq(l) {
    width: 100rem;
    max-width: 80vw;
    min-height: 50vh;

    .affiliation-keyfacts & {
      min-height: 0;
    }
  }
}

.team-popup__picture {
  position: relative;
  flex: 0;

  img {
    @include image-fit;
  }

  @include mq(s) {
    flex: 40rem 0 1;
    min-width: 30rem;
    height: auto;
    padding: 0;
  }

  @include mq(l) {
    min-width: 30%;
  }
}

.team-popup__content {
  flex: 1;
  padding: $spacing;
  color: $c-black;
  background: $c-white;

  @include mq(s) {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $spacing * 2.5;
  }
}

.team-popup__content__title {
  @include mq($until: m) {
    padding-right: $spacing * 3.5;
  }

  @include mq(l) {
    .affiliation-keyfacts & {
      font-size: 3.2rem;
    }
  }
}

.team-popup__content__job {
  color: $c-black-80;
}

.team-popup__content__description {
  @extend %fw-normal;

  margin-top: $spacing;
  color: $c-black-80;

  @include mq(l) {
    max-width: 70%;
  }
}

.team-popup__content__infos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: $spacing * 2.5;

  .icon {
    color: $c-blue-80;
  }

  @include mq(l) {
    flex-direction: row;
    align-items: center;
    margin-top: auto;
  }
}

.team-popup__content__infos__link {
  & + & {
    margin-top: $spacing;
  }

  @include mq(l) {
    & + & {
      margin: 0 0 0 $spacing * 2;
    }
  }
}

.team-popup__content__address {
  margin-right: auto;

  @include mq(l) {
    padding-bottom: $spacing * 2.25;
  }
}
