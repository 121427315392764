.cookiebar {
  --cookiebar-c-primary: #2b2b2b; // $c-black
  --cookiebar-c-secondary: #e9e9e9; // $c-black-40;
  --cookiebar-c-secondary-light: #f8f8f8; // $c-black-10

  position: relative;
  z-index: 100;
}

.cookiebar-controls,
.cookiebar-selection {
  font-family: $ff-default;
  border-radius: 1rem;
  box-shadow: 0 1rem 2.2rem rgba(0, 0, 0, 0.06);
}

.cookiebar-controls {
  right: 2rem;
  bottom: 2rem;
  width: calc(100% - 4rem);
  max-width: 90rem;

  /* stylelint-disable property-no-unknown */
  container-name: controls;
  container-type: inline-size;
  /* stylelint-enable property-no-unknown */
}

.cookiebar__title {
  @extend .g-button;

  color: $c-black;
  font-size: 1.6rem;
}

.cookiebar__text {
  @extend %fw-normal;

  color: $c-black-70;
  line-height: 2.2rem;
}

.cookiebar-controls__split {
  margin: 1.25rem 0;
  background: none;
}

.cookiebar__actions,
.cookiebar__actions__primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing * 0.5;

  @container controls (min-width: 65rem) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.cookiebar__actions__primary {
  .cookiebar-controls & {
    order: -1;
  }
}

.cookiebar__action,
[class*='cookiebar__action--'] {
  @extend %text-center;
  @extend .g-button-uppercase;

  margin: 0;
  border: 0;

  &[class*='--link']::before {
    content: none;
  }

  &[data-ref='cookiebar.deny-all'],
  &[data-ref='cookiebar.accept-selection'],
  .cookiebar-controls &[data-ref='cookiebar.accept-all-cookies'] {
    padding: 1.7rem 3.6rem;
    color: $c-blue;
    background: none;
    border: 3px solid $c-gray-lighter;
    border-radius: 6rem;
    box-shadow: none;

    svg {
      display: none;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus-visible {
      box-shadow: none;
    }
  }
}

.cookiebar__item__details h3 {
  @extend .g-small;

  color: $c-blue;
}

.cookiebar__item__details__duration {
  height: auto;
  margin: 0;
  padding: 20px 0;
}

.cookiebar-selection-outer {
  width: calc(100% - 4rem);
  max-width: 70rem;
}

.cookiebar-selection__footer {
  box-sizing: content-box;
  height: auto;
  padding: $spacing * 2;
}
