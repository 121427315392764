
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































.team-grid__list {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;
  gap: $spacing * 2;

  @include mq(s) {
    flex-flow: row wrap;
  }

  @include mq(l) {
    gap: $spacing * 2;
  }

  @include mq(wrapper) {
    gap: $spacing * 3;
  }
}

.team-grid__item {
  @include mq(s) {
    flex-basis: calc(calc(100% - #{$spacing * 2}) * 0.5);
  }

  @include mq(l) {
    flex: calc(calc(100% - #{$spacing * 4}) * 0.333) 0 1;
  }

  @include mq(wrapper) {
    flex: calc(calc(100% - #{$spacing * 6}) * 0.333) 0 1;
  }
}
