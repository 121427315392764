
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.not-found__text {
  @extend %text-center;

  color: $c-black-80;
}
