.wysiwyg,
[class*='wysiwyg--'] {
  @extend %fw-normal;

  color: rgba(0, 0, 0, 0.6);
  line-height: 2.8rem;
  letter-spacing: 0.5px;

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  h6,
  .h6 {
    @extend .h6;
    @include fluid(
      margin-top,
      (
        xxs: 60px,
        xl: 80px,
      )
    );
    @include fluid(
      margin-bottom,
      (
        xxs: 20px,
        m: 32px,
      )
    );

    color: $c-black;
  }

  p {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 40px));
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    @extend %list-nostyle;

    margin-top: $spacing * 1.3;
    margin-bottom: $spacing * 2;
  }

  ol {
    list-style: none;
    counter-reset: li attr(start);

    @for $i from 1 through 100 {
      &[start='#{$i}'] {
        counter-reset: li calc(#{$i} - 1);
      }
    }
  }

  li {
    position: relative;
    margin-bottom: $spacing * 0.3;
    padding-left: $spacing * 1.5;
    line-height: 1.4em;

    &::before {
      position: absolute;
      left: 0;
    }
  }

  ul li {
    margin-bottom: $spacing * 0.3;

    &::before {
      content: '';
      top: calc(calc(1.4em * 0.5) - 0.2rem);
      width: 0.4rem;
      height: 0.4rem;
      margin-right: $spacing;
      background: $c-blue-80;
      border-radius: 50%;
    }
  }

  ol li {
    counter-increment: li;

    &::before {
      @extend %fw-medium;

      content: counter(li) '.';
      display: inline-block;
      width: 2.4rem;
      color: $c-blue-80;
    }
  }

  a {
    @extend %fw-medium;

    color: $c-blue-80;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.1em;
    transition: color 0.2s;

    &:hover,
    &:focus-visible {
      color: $c-blue-60;
    }
  }

  strong {
    @extend %fw-medium;

    color: $c-blue-80;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-black;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  img {
    margin: auto;
  }
}
