
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































.card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1.4rem;
  transition: all 1s $ease-out-expo;

  &:not(.is-highlight):hover,
  &:not(.is-highlight):focus-within {
    transform: translateY(-5px);
    box-shadow: 0 20px 60px rgba($c-black, 0.2);
  }

  &.is-dark {
    color: $c-white;
  }

  @include mq(l) {
    max-width: 34.4rem;

    &.is-highlight {
      max-width: none;
    }
  }
}

.card__label {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: $spacing * 1.4 $spacing * 1.6;
  padding: $spacing * 0.15 $spacing * 0.8 $spacing * 0.15 $spacing * 0.6;
  color: $c-white;
  background: rgba($c-black-true, 0.5);
  border-radius: 5rem;
  backdrop-filter: blur(3px);

  .icon {
    margin-right: $spacing * 0.35;
    color: $c-green-80;
  }
}

.card__picture {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 85%;

  img {
    @include image-fit;
  }

  .is-dark & {
    position: static;

    &::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      background: $gradient-card;
    }
  }

  .is-highlight & {
    position: relative;
    padding-bottom: 75%;
  }

  @include mq(m) {
    .is-highlight & {
      position: static;
      padding-bottom: 33%;
      cursor: none;
    }
  }
}

.card__content {
  position: relative;
  z-index: 1;
  flex: 1;
  overflow: hidden;
  padding: $spacing * 1.4 $spacing * 3 $spacing * 1.4 $spacing * 1.6;
  background: $c-white;

  .is-dark & {
    background: none;
  }

  .is-highlight & {
    padding-bottom: $spacing * 2.5;
    background: $c-blue-100;
  }

  @include mq(m) {
    .is-highlight & {
      padding: 0 col(1, 18) $spacing * 3.5;
      background: transparent;
    }
  }

  @include mq(xxl) {
    .is-highlight & {
      padding: 0 col(1, 16) $spacing * 3.5;
    }
  }
}

.card__category {
  .is-highlight & {
    color: $c-blue-40;
  }
}

.card__category__full {
  @extend %fw-normal;

  display: inline-block;
  margin-left: $spacing;
  padding: $spacing * 0.05 $spacing * 0.7;
  color: $c-black-80;
  line-height: 2.8rem;
  background: $c-black-40;
  border-radius: 5rem;
}

.card__title {
  margin: $spacing * 0.4 0;

  .is-highlight & {
    margin: $spacing * 0.6 0;
  }

  .card:not(.is-highlight) & {
    font-size: 2.4rem;
  }

  @include mq(m) {
    .is-highlight & {
      margin: $spacing * 1.4 0 $spacing * 1.1;
    }
  }
}

.card__text {
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
  overflow: hidden;
  font-size: 1.4rem;

  .is-dark & {
    color: $c-white;
  }
}

.card__date--highlight {
  @extend %fw-normal;

  ::v-deep span {
    margin-right: 0.25em;
  }
}

.card__address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: $spacing * 1.8;
  color: $c-white;

  .icon {
    margin-right: $spacing * 0.5;
    color: $c-green-80;
  }

  @include mq($until: m) {
    @include visually-hidden;
  }
}

.card__link {
  @include get-all-space;

  z-index: 1;
}

.card__link__label {
  @extend %visually-hidden;
}

.card__link__button {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  cursor: pointer;

  ::v-deep {
    .button-magnetic {
      top: calc(50% - 3rem);
      left: calc(50% - 3rem);

      .is-highlight & {
        top: 75%;
        right: col(1);
        left: initial;
      }
    }
  }

  @include mq(m) {
    cursor: none;
    height: 100%;
    padding-bottom: 0;

    ::v-deep {
      .button-magnetic-outer {
        width: 100%;
        height: 100%;
        margin: auto;
        padding-bottom: 0;
      }

      .is-highlight & .button-magnetic {
        bottom: 10rem;
      }
    }
  }
}
