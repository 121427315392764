html {
  font-size: calc(1em * 0.625);
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 1.6rem;
  line-height: 1.75;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h7,
.h8 {
  @extend %fw-medium;

  display: block;
  margin: 0;
  line-height: calc(0.8rem + 1em);
}

h1,
.h1 {
  @include fluid(
    font-size,
    (
      xxs: 44px,
      xxl: 114px,
    )
  );

  line-height: calc(1rem + 1em);
}

h2,
.h2 {
  @include fluid(
    font-size,
    (
      xxs: 40px,
      xxl: 90px,
    )
  );

  @include mq(l) {
    line-height: calc(1rem + 1em);
  }
}

h3,
.h3 {
  @include fluid(
    font-size,
    (
      xxs: 32px,
      xxl: 56px,
    )
  );
}

h4,
.h4 {
  @include fluid(
    font-size,
    (
      xxs: 28px,
      xxl: 44px,
    )
  );
}

h5,
.h5 {
  @include fluid(
    font-size,
    (
      xxs: 22px,
      xxl: 34px,
    )
  );
}

h6,
.h6 {
  @include fluid(
    font-size,
    (
      xxs: 20px,
      xxl: 26px,
    )
  );
}

.h7 {
  @include fluid(
    font-size,
    (
      xxs: 18px,
      xxl: 24px,
    )
  );
}

a {
  color: currentColor;
  text-decoration: none;
}

.g-main-title {
  @extend %text-center;

  max-width: col(14, 16);
  margin: 0 auto $spacing * 0.6;

  @include mq(l) {
    margin-bottom: $spacing * 1.5;
  }
}

.g-big-title {
  @extend %text-center;

  strong {
    /* stylelint-disable */
    background: -webkit-linear-gradient(
      left,
      $c-gradient-light,
      $c-gradient-dark
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable */
  }
}

.g-baseline {
  @extend %fw-normal;
  @extend %text-center;
  @include fluid(
    font-size,
    (
      xxs: 18px,
      xxl: 28px,
    )
  );

  max-width: 30em;
  margin-right: auto;
  margin-left: auto;
  color: $c-black-80;
  line-height: calc(0.8rem + 1em);
}

.g-lead {
  @include fluid(
    font-size,
    (
      xxs: 18px,
      xxl: 28px,
    )
  );
  @include fluid(
    line-height,
    (
      xxs: 28px,
      xxl: 40px,
    )
  );
}

.g-navigation-item {
  color: $c-black-navigation;
  font-size: 1.7rem;
  line-height: 2.8rem;
  transition: color 0.2s;

  &[href]:hover,
  &[href]:focus-visible {
    color: $c-black;
  }
}

.g-button {
  @extend %fw-medium;

  color: $c-blue-60;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.g-button-uppercase {
  @extend %fw-bold;
  @extend %text-uppercase;

  color: $c-blue-80;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 1.5px;
}

.g-subtitle {
  @extend %fw-medium;

  color: $c-blue-60;
  line-height: 1.5;
}

.g-light {
  @extend %fw-normal;

  color: rgba($c-black, 0.6);
  line-height: 2.6rem;
}

.g-small {
  color: rgba($c-black, 0.5);
  font-size: 1.5rem;
  line-height: 1.625;
}

.g-x-small {
  color: rgba($c-black, 0.4);
  font-size: 1.4rem;
  line-height: 2;
}

.g-link {
  color: $c-blue-80;
  transition: color 0.2s;

  &:hover,
  &:focus-visible {
    color: $c-blue-60;
  }
}

.g-link-underline {
  text-decoration: underline;
  transition: color 0.1s;

  &:hover,
  &:focus-visible {
    color: $c-blue-80;
  }
}
