
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































































.chrome-submenu {
  @include mq(l) {
    display: flex;
  }
}

.chrome-submenu__trigger {
  @extend %button-nostyle;

  position: relative;
  width: fit-content;
  margin: auto;

  @include mq(l) {
    display: flex;
    align-items: center;
    margin: 0;

    // makes hoverable zone bigger
    &::after {
      content: '';
      position: absolute;
      bottom: -3rem;
      left: 0;
      width: 100%;
      height: 3rem;
    }
  }
}

.chrome-submenu__trigger__icon {
  @include center-y;

  right: 0;
  padding: 1rem;
  color: rgba($c-white, 0.6);
  transform: translate(100%, -50%);

  .icon {
    vertical-align: middle;
  }

  &:focus-visible {
    outline: $c-blue-40 2px solid;
  }

  @include mq(l) {
    position: static;
    padding-right: 0;
    color: $c-black-70;
    transform: none;
  }
}

.chrome-submenu__list {
  @extend %list-nostyle;

  max-height: 0;
  overflow: hidden;
  transition: 0.5s;

  .chrome-submenu.is-open & {
    max-height: 40vh;
  }

  @include mq(l) {
    position: absolute;
    top: 100%;
    left: -3.4rem;
    max-height: none;
    margin-top: $spacing * 0.75;
    padding: $spacing * 0.4;
    background: $c-white;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    opacity: 0;
    white-space: nowrap;
    transform: translateY(10px);
    pointer-events: none;
    transition: opacity 0.5s, transform 0.5s, pointer-events 0s 0.5s;

    .header__menu__link:hover &,
    .chrome-submenu.is-open &,
    .chrome-submenu__trigger:focus + &,
    &:focus-within {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
      transition: all 0.5s;
    }
  }
}

.chrome-submenu__item {
  @include mq($until: l) {
    .g-navigation-item {
      color: rgba($c-white, 0.8);
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
}

.chrome-submenu__item__link {
  display: block;
  width: fit-content;
  margin: auto;
  padding: $spacing $spacing 0;

  & + & {
    padding-top: $spacing * 0.7;
  }

  @include mq($until: l) {
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: rgba($c-white, 0.8);
  }

  @include mq(l) {
    width: 100%;
    margin: 0;
    padding: $spacing * 0.6 $spacing * 1.3;
    border-radius: 0.6rem;
    transition: color 0.5s, background 0.5s;

    &:hover,
    &:focus-visible {
      color: $c-blue-80;
      background: $c-blue-10;
    }
  }
}
