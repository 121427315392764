
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































$radius: 1.5rem;

.team-card,
[class*='team-card--'] {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  border-radius: $radius;
  transition: all 1s $ease-out-expo;

  &:hover {
    transform: translateY(-5px) translateZ(0);
    box-shadow: 0 20px 60px rgba($c-black, 0.2);
  }

  @media (pointer: fine) {
    &:focus-within {
      transform: translateY(-5px) translateZ(0);
      box-shadow: 0 20px 60px rgba($c-black, 0.2);
    }
  }
}

.team-card__picture,
[class*='team-card__picture--'] {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 95%;

  img {
    @include image-fit;

    z-index: 0;
    border-radius: inherit;
    transform: scale(1.05);
    transition: transform 1s $ease-out-expo;

    .team-card:hover & {
      transform: scale(1);
    }
  }

  &[class*='--fun'] {
    position: absolute;
    z-index: 1;
    width: 100%;
    opacity: 0;
    transition: opacity 0.1s;

    .team-card:hover & {
      opacity: 1;
    }
  }

  @media (pointer: fine) {
    .team-card:focus-within & {
      opacity: 1;

      img {
        transform: scale(1);
      }
    }
  }
}

.team-card__logo {
  background: $c-white;
}

.team-card__logo__picture {
  position: relative;
  width: 14.8rem;
  height: 7.6rem;
  margin: auto;
  background: $c-white;
  border: 1px solid $c-black-40;
  border-radius: 0.4rem;
  transform: translateY(-50%);

  // stylelint-disable-next-line no-descending-specificity
  img {
    @include image-fit(contain);

    padding: $spacing * 0.5 $spacing;
  }
}

.team-card__content {
  flex: 1;
  padding: $spacing * 1.6 $spacing * 1.6 $spacing * 2.2;
  text-align: left;
  background: $c-white;

  .team-card__logo + & {
    padding-top: 0;
  }
}

.team-card__content__function {
  @extend %fw-normal;

  color: rgba($c-black, 0.6);
}

.team-card__button {
  @extend %button-nostyle;

  &::after {
    @include get-all-space;

    content: '';
    z-index: 1;
  }
}

.team-card__link__button {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  opacity: 0;
  cursor: pointer;

  ::v-deep {
    .button-magnetic {
      top: calc(50% - 3rem);
      left: calc(50% - 3rem);
    }
  }

  .team-card:hover & {
    opacity: 1;
    transition: opacity 0.3s 0.3s;
  }

  @include mq(m) {
    // cursor: none;
    height: 100%;
    padding-bottom: 0;

    ::v-deep {
      .button-magnetic-outer {
        width: 100%;
        height: 100%;
        margin: auto;
        padding-bottom: 0;
      }
    }
  }
}
