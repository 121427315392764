
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































// Defaults
.action,
[class*='action--'] {
  display: inline-block;
  color: $c-black;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

[class*='action--'][class*='--icon'] {
  display: inline-flex;
  align-items: center;
}

[class*='action--'][class*='--icon'][class*='--reverse'] {
  flex-direction: row-reverse;
}

// Links
[class*='action--'][class*='--link'] {
  @extend %fw-medium;

  color: $c-black;
  text-decoration: none;
  letter-spacing: 0.5px;
  transition: 0.2s;

  &:hover,
  &:focus-visible {
    color: $c-blue-80;
  }

  &[class*='--negative'] {
    color: $c-white;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  @extend %fw-medium;

  position: relative;
  padding: 1.5rem 3.45rem 1.4rem;
  color: $c-blue-60;
  // line-height: 1.5;
  line-height: 2.8rem;
  letter-spacing: 0.5px;
  background: $c-white;
  border: 0;
  border-radius: 6rem;
  transition: 0.3s $ease-out-cubic;

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus-visible {
    background: $c-black-10;
  }
}

[class*='action--'][class*='--btn'][class*='--no-label'] {
  padding: 1.5rem 1.5rem 1.4rem;
}

[class*='action--'][class*='--btn'][class*='--small'] {
  padding: 1.6rem 3rem 1.4rem;
  font-size: 1.6rem;
}

[class*='action--'][class*='--btn'][class*='--uppercase'] {
  @extend %fw-bold;
  @extend %text-uppercase;

  color: $c-blue-80;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 1.5px;
}

[class*='action--'][class*='--btn'][class*='--bordered'] {
  border: 3px solid $c-black-40;

  &:not([disabled]):hover,
  &:not([disabled]):focus-visible {
    background: $c-white;
    border-color: $c-black-60;
  }
}

[class*='action--'][class*='--btn'][class*='--dark'] {
  color: $c-white;
  background: $c-blue-60;

  &:not([disabled]):hover,
  &:not([disabled]):focus-visible {
    background: $c-blue-80;
  }
}

// Miscellaneous
[class*='action--'][class*='--social'] {
  display: flex;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  background: $c-blue-30;
  border-radius: 50%;
  transition: background 0.2s, transform 0.2s;

  &:hover,
  &:focus-visible {
    background: $c-blue-40;
  }

  &[class*='--big'] {
    width: 4rem;
    height: 4rem;
  }
}

.action__label {
  $label-offset: 1em;

  display: inline-block;
  color: currentColor;
  transition: color 0.5s $ease-out-cubic;

  [class*='action--'][class*='--social'] &,
  [class*='action--'][class*='--no-label'] & {
    @extend %visually-hidden;
  }

  [class*='action--'][class*='--btn'] & {
    transition: 0.3s $ease-out-cubic;

    &::before {
      @include center-x;

      content: attr(data-label);
      z-index: 1;
      width: 100%;
      margin-top: $label-offset;
      color: $c-blue-80;
      opacity: 0;
      transition: 0.2s $ease-out-cubic;
    }
  }

  [class*='action--'][class*='--underline'] & {
    color: $c-blue-80;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.1em;
  }

  [class*='action--'][class*='--btn'][class*='--dark'] & {
    &::before {
      color: $c-white;
    }
  }

  [class*='action--'][class*='--underline']:hover &,
  [class*='action--'][class*='--underline']:focus-visible & {
    color: $c-blue-60;
  }

  [class*='action--'][class*='--btn']:not([disabled]):hover &,
  [class*='action--'][class*='--btn']:not([disabled]):focus-visible & {
    color: transparent;
    transform: translate3d(0, -$label-offset, 0);
    transition: 0.3s $ease-out-cubic;

    &::before {
      opacity: 1;
      transition: 0.3s $ease-in-cubic;
    }
  }
}

.action__icon-wrapper {
  display: flex;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: $spacing * 0.5;
  pointer-events: none;

  [class*='action--'][class*='--reverse'] {
    margin: 0 0 0 $spacing * 0.5;
  }

  [class*='action--'][class*='--arrow'] & {
    width: 1.6rem;
    height: 1.6rem;
  }

  [class*='action--'][class*='--social'] & {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0;
  }

  [class*='action--'][class*='--no-label'] & {
    margin: 0;
  }

  [class*='action--'][class*='--social'][class*='--chevron'] & {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.4rem;
    transform-origin: center;
    transition: 0.5s;

    .is-open & {
      margin-top: 0;
      transform: rotate(180deg);
    }
  }
}

.action__icon {
  fill: currentColor;

  [class*='action--'][class*='--link'] & {
    color: currentColor;
  }

  [class*='action--'][class*='--linkedin'] & {
    color: currentColor;
  }

  [class*='action--'][class*='--no-label'] & {
    color: currentColor;
  }
}
