
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.button-close,
[class*='button-close--'] {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: $c-blue-10;
  border-radius: 50%;
  cursor: pointer;

  &.big {
    width: 6rem;
    height: 6rem;

    @include mq(l) {
      width: 7.4rem;
      height: 7.4rem;
    }
  }
}

.button-close__label {
  @extend %visually-hidden;
}

.button-close__dots {
  position: relative;
  width: 2rem;
  height: 2rem;
  transition: transform 0.4s $ease-out-back;
  transform-origin: center;

  .button-close:hover &,
  button:focus-visible > .button-close & {
    transform: rotate(180deg);
  }

  &.big {
    width: 2.4rem;
    height: 2.4rem;

    @include mq(l) {
      width: 3rem;
      height: 3rem;
    }
  }
}

.button-close__dot {
  position: absolute;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  background: $c-blue-80;
  border-radius: 0.5rem;
  transition: all 0.4s $ease-out-back;

  .big & {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.2rem;
  }

  &.top-left {
    top: 0;
    left: 0;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: top left;

    .button-close:hover &,
    button:focus-visible > .button-close & {
      transform: rotate(45deg) translate(5px, -50%);
    }
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top right;

    .button-close:hover &,
    button:focus-visible > .button-close & {
      transform: rotate(-45deg) translate(-5px, -50%);
    }
  }

  &.center {
    @include center-xy;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(-45deg) translateY(50%);
    transform-origin: bottom left;

    .button-close:hover &,
    button:focus-visible > .button-close & {
      transform: rotate(-45deg) translate(5px, 50%);
    }
  }

  &.bottom-right {
    right: 0;
    bottom: 0;
    transform: rotate(45deg) translateY(50%);
    transform-origin: bottom right;

    .button-close:hover &,
    button:focus-visible > .button-close & {
      transform: rotate(45deg) translate(-5px, 50%);
    }
  }

  .button-close:hover &,
  button:focus-visible > .button-close & {
    &:not(.center) {
      width: 1.5rem;
    }
  }
}
