
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































.about-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 2.5 $spacing;
  justify-content: center;
  margin: 0;
  padding: 0;
  opacity: 0;
  list-style-type: none;
}

.about-number {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .icon {
    max-width: 8rem;
    margin: auto;
    color: $c-blue-80;
  }

  .big & {
    .icon {
      max-width: 12rem;
      color: $c-blue-80;
    }
  }

  @include mq(wrapper) {
    .icon {
      max-width: 12rem;
    }

    .big & {
      .icon {
        width: auto;
        max-width: none;
        height: 15rem;
      }
    }
  }
}

.about-number__value {
  @include fluid(
    font-size,
    (
      xxs: 32px,
      xxl: 64px,
    )
  );
}

.about-number__label {
  color: $c-black-80;
}
