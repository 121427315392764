
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































































































.button-magnetic {
  position: absolute;
  z-index: 1;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 5.2rem;
  height: 5.2rem;
  color: $c-white;
  background: $c-green-80;
  border-radius: 50%;
  transform-origin: center;

  &.play {
    width: 6rem;
    height: 6rem;
    color: $c-green-80;
    background: rgba($c-black, 0.5);
    backdrop-filter: blur(2px);

    .icon {
      width: 1.2rem;
    }
  }

  &:not(.has-label) {
    .button-magnetic__label {
      @extend %visually-hidden;
    }
  }

  @include mq(m) {
    width: 7.6rem;
    height: 7.6rem;

    &.has-label {
      width: fit-content;
      height: 3.6rem;
      padding: 1rem 2rem;
      border-radius: 10rem;

      .icon {
        width: 1.2rem;
      }
    }
  }
}

// .button-magnetic__label:not(.has-label &) {
//   @extend %visually-hidden;

//   .has-label & {
//     position: initial;
//     overflow: initial;
//     width: initial;
//     height: initial;
//     margin: initial;
//     padding: initial;
//     border: initial;
//     clip: rect(0 0 0 0);
//   }
// }
