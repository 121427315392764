
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.team-cta {
  &.dark {
    padding-right: col(1, 18);
    padding-left: col(1, 18);
    color: $c-white;
    background: $c-blue-100;
    border-radius: 2.4rem;
  }

  @include mq(l) {
    &.dark {
      padding-right: col(1, 16);
      padding-left: col(1, 16);
    }
  }
}

.team-cta__content__main {
  margin-top: 0;

  @include mq(m) {
    display: block;
  }
}

.team-cta__title {
  @extend %text-center;

  color: $c-black-80;
  font-size: 1.6rem;

  .dark & {
    color: rgba($c-white, 0.6);
  }

  @include mq(m) {
    text-align: left;
  }

  @include mq(m) {
    margin-bottom: $spacing * 0.8;
    font-size: 1.8rem;
  }
}

.team-cta__content {
  @extend %fw-medium;
  @extend %text-center;
  @include fluid(
    font-size,
    (
      xxs: 40px,
      xxl: 70px,
    )
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;

  > * {
    margin-top: $spacing * 1.2;
  }

  @include mq(m) {
    display: block;
    text-align: left;

    > * {
      margin-top: 0;
    }
  }
}

.team-cta__content__item {
  display: inline-block;
  margin-top: $spacing;

  &:last-child::after {
    content: '.';
  }

  @include mq(m) {
    margin-top: 0;
  }
}

.team-cta__button {
  @extend %button-nostyle;
  @include fluid(
    font-size,
    (
      xxs: 24px,
      xxl: 34px,
    )
  );

  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding: 1rem 2.7rem 1rem 1.2rem;
  color: $c-blue-40;
  background: $c-blue-80;
  border-radius: 6rem;

  .team-cta__content__item ~ .team-cta__content__item & {
    color: $c-green;
    background: $c-green-80;
  }
}

.team-cta__button__picture {
  position: relative;
  z-index: -1;
  width: 4.4rem;
  height: 4.4rem;
  margin-right: $spacing * 0.6;
  border-radius: 50%;

  img {
    @include image-fit;

    border-radius: 50%;
  }
}

.team-cta__content__separator {
  margin-inline: 0.1em;
}
