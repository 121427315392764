
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































$offset: 40px;

.g-picture,
[class*='g-picture--'] {
  img {
    @include center-x;

    top: -$offset;
    bottom: initial;
    height: calc(100% + #{$offset});
    will-change: top;
  }
}
