
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































































.footer,
[class*='footer--'] {
  padding: $spacing * 2.5 0 $spacing * 3;

  [data-template='single'] & {
    display: none;
  }

  @include mq(m) {
    padding: $spacing * 2 0 $spacing * 3;
  }
}

.footer__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: $spacing * 2;

  @include mq(l) {
    flex-direction: row;
    padding-bottom: $spacing * 3.5;
  }
}

.footer__infos__logo {
  display: block;
  margin-bottom: $spacing * 2.2;

  .icon {
    color: $c-blue-logo;
  }

  @include mq(l) {
    flex-shrink: 0;
    margin: 0 col(1.5, 18) 0 0;

    .icon {
      width: 19.5rem;
      height: 6.375rem;
    }
  }

  @include mq(l) {
    margin-right: col(1, 18);
  }
}

.footer__infos__list {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;

  &:first-of-type {
    margin-bottom: $spacing * 1.8;
  }

  @include mq(l) {
    justify-content: flex-end;

    &:first-of-type {
      justify-content: flex-start;
      flex: 1;
      margin-bottom: 0;
    }
  }
}

.footer__infos__item {
  [class*='action'] {
    color: $c-black-80;
  }

  & + & {
    margin-left: $spacing * 1.5;
  }

  .footer__infos__list:first-of-type & + & {
    margin-left: $spacing * 2.4;
  }

  ::v-deep {
    .action__icon {
      fill: $c-blue-logo;
    }
  }

  @include mq(l) {
    .footer__infos__list:first-of-type & + & {
      margin-left: $spacing * 2.7;
    }
  }
}

.footer__contact {
  border-top: 2px solid $c-black-10;
}

.footer__contact__list,
.footer__legals__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: $spacing * 2;

  @include mq($until: m) {
    padding-right: col(2, 20);
    padding-left: col(2, 20);
  }

  @include mq(l) {
    flex-direction: row;
    padding-top: $spacing * 2.8;
  }
}

.footer__contact__item {
  @extend %fw-medium;

  & + & {
    margin-top: $spacing;
  }

  &:last-child {
    @extend %fw-normal;

    margin-top: $spacing * 1.4;
    color: $c-black-80;
    letter-spacing: 0.5px;
  }

  &::v-deep {
    .action__icon {
      margin-right: 0.5rem;
      fill: $c-blue-60;
    }
  }

  @include mq(l) {
    margin-left: $spacing * 2.8;

    & + & {
      margin-top: 0;
    }

    &:last-child {
      flex: 1;
      order: -1;
      margin: 0;
    }
  }
}

.footer__legals__list {
  padding-top: $spacing * 4;

  @include mq(l) {
    padding-top: $spacing * 4;
  }
}

.footer__legals__item {
  @extend %fw-normal;
  @extend %text-center;

  color: $c-black-80;
  font-size: 1.2rem;
  letter-spacing: 0.5px;

  & + & {
    margin-top: $spacing;
  }

  @include mq(l) {
    text-align: left;

    &:first-child {
      flex: 1;
      padding-right: col(2, 18);
    }

    & + & {
      margin: 0 0 0 $spacing * 2;
    }
  }
}

.footer__legals__item__link {
  transition: color 0.2s;

  .icon {
    color: $c-black-60;
    transition: color 0.2s;
  }

  &:focus-visible,
  &[href]:hover {
    color: $c-black;

    .icon {
      color: $c-black-80;
    }
  }
}
