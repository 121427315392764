
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































































.form-select-outer {
  position: relative;

  .form & {
    flex-basis: 100%;
    margin: 0 $spacing * 0.5 $spacing;
  }

  @include mq(m) {
    .form & {
      margin: 0 $spacing * 0.5 3.2rem;
    }
  }
}

.form-select {
  position: relative;
  display: flex;
  align-items: center;
  background: $c-white;
  border-radius: 8px;
  box-shadow: 0 2.92px 14.6px rgba(0, 0, 0, 0.06);
  transition: border-color 0.2s;

  .form & {
    background: $c-black-10;
    border: 3px solid $c-black-10;
    border-radius: 6rem;
    box-shadow: none;
  }

  &:hover {
    border-color: $c-black-40;
  }

  &:focus {
    border-color: $c-black-60;
  }

  &.is-invalid {
    border-color: $c-red;
  }
}

.form-select__icon {
  @include center-y;

  left: col(1, 18);
  color: rgba($c-black, 0.4);
  pointer-events: none;

  & ~ & {
    right: col(1, 18);
    left: initial;
  }

  .form &:first-child {
    display: none;
  }
}

.form-select__select {
  @extend %select-nostyle;

  width: 100%;
  padding: $spacing * 0.8 calc(#{col(1, 18)} + 4.4rem) $spacing * 0.8;
  color: $c-black;
  cursor: pointer;

  .form & {
    padding-left: 3.2rem;
    color: $c-black-80;
  }
}

.form-feedback {
  position: absolute;
  bottom: -1rem;
  white-space: nowrap;
  opacity: 0;

  @include mq(m) {
    bottom: -3rem;
    opacity: 1;
  }
}
