
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































.flexible-block-cta {
  position: relative;
  padding-right: $spacing;
  padding-left: $spacing;
  background: $c-black-10;
  border-radius: 2rem;
  transition: all 1s $ease-out-expo;

  &:hover,
  &:focus-within {
    transform: translateY(-5px);
    box-shadow: 0 20px 30px rgba($c-black, 0.1);
  }

  @include mq(l) {
    padding-right: $spacing * 2.5;
    padding-left: $spacing * 2.5;
  }
}

.flexible-block-cta__title {
  max-width: 20em;
  margin: $spacing * 0.75 0;
}

.flexible-block-cta__link {
  &::after {
    @include get-all-space;

    content: '';
  }
}
