
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































































































































































































































.header,
[class*='header--'] {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  background: $c-white;
  transition: box-shadow 0.4s;

  &.is-down {
    position: fixed;
    background: $c-white;
    transform: translateY(-100%);
    transition: transform 0.4s, background 0.4s, box-shadow 0.4s;

    &::after {
      $after-height: 20px;

      content: '';
      position: absolute;
      bottom: -$after-height;
      display: block;
      width: 100%;
      height: $after-height;
    }
  }

  &.is-visible,
  &.is-down:hover {
    position: fixed;
    background: $c-white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    transform: translateY(0);
    transition: transform 0.4s, background 0.4s, box-shadow 0.4s;
  }

  [data-template='single'] & {
    display: none;
  }
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding-top 0.5s, padding-bottom 0.5s;

  .is-down & {
    padding-top: $spacing * 0.5;
    padding-bottom: $spacing * 0.5;
  }

  @include mq($from: l, $until: xl) {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}

.header__logo {
  display: flex;

  .icon {
    color: $c-blue-logo;
  }

  @include mq(l) {
    flex-shrink: 0;
    width: col(2, 18);
    min-width: 13rem;

    .icon {
      width: 19.5rem;
      height: 3.375rem;
    }
  }
}

.header__toggle {
  @extend %button-nostyle;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: $c-blue-10;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;

  .icon {
    color: $c-blue-80;
  }

  &:hover,
  &:focus-visible {
    background: $c-blue-30;
  }
}

.header__menu {
  @include scrollbars(8px, $c-blue-logo, transparent);

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  padding: $spacing * 6 $spacing $spacing * 3;
  background: $c-blue;

  &.is-hidden {
    @include visually-hidden;
  }

  @include mq(l) {
    position: static;
    flex-direction: row;
    overflow: visible;
    height: 100%;
    padding: 0;
    background: none;
  }
}

.header__menu__close {
  @extend %button-nostyle;

  position: fixed;
  top: $spacing;
  right: $spacing;
  align-self: flex-end;

  .button-close {
    width: 5rem;
    height: 5rem;
  }
}

.header__menu__links {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq(l) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }
}

.header__menu__link {
  &::v-deep {
    .action__icon {
      margin-right: 0.5rem;
      fill: $c-blue-80;
    }
  }

  @include mq($until: l) {
    padding: $spacing * 0.6 0;
    text-align: center;

    &:nth-last-child(2) {
      margin-top: $spacing * 3.5;
    }
  }

  @include mq(l) {
    position: relative;

    & + & {
      margin: 0 0 0 5%;

      &:last-child {
        margin-left: $spacing * 0.75;
      }
    }
  }

  @include mq(xl) {
    & + & {
      margin-left: $spacing * 2.6;
    }
  }
}

.header__menu__link__inner,
[class*='header__menu__link__inner'] {
  &[class*='--btn'] {
    white-space: nowrap;
  }

  @include mq(l) {
    display: flex;

    &[class*='--btn'] {
      padding-right: $spacing * 1.5;
      padding-left: $spacing * 1.5;
    }

    &[class*='--my-cci'] {
      padding: 1.4rem $spacing 1.3rem;
      padding-right: $spacing;
      padding-left: $spacing;
      color: $c-black-navigation;
      line-height: 2.4rem;
      border: 3px solid $c-black-40;
    }
  }
}

::v-deep .g-navigation-item {
  @include mq($until: l) {
    color: $c-white;
    font-size: 4rem;
    font-weight: 500;
    line-height: 4.8rem;

    &:hover,
    &:focus {
      color: $c-blue-30;
    }
  }
}
