
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































.agenda-location,
[class*='agenda-location--'] {
  display: flex;
  align-items: center;
  margin-top: $spacing * 0.8;
  color: $c-black-80;

  .icon {
    flex-shrink: 0;
    margin-right: $spacing * 0.5;
    color: $c-green-80;

    ul & {
      color: $c-black;
    }
  }
}
