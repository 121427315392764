
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































.g-flexible,
[class*='g-flexible--'] {
  > div {
    margin-top: 0;
  }
}
