
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































.g-faq__intro {
  @extend %text-center;

  margin-right: auto;
  margin-left: auto;

  @include mq(m) {
    max-width: col(14, 18);
  }

  @include mq(wrapper) {
    max-width: col(12, 16);
  }
}

.g-lead {
  padding: 0 col(2, 18);
  color: rgba($c-black, 0.6);

  ::v-deep strong {
    color: $c-blue-80;
  }

  @include mq(m) {
    padding: 0 col(2, 14);
  }

  @include mq(wrapper) {
    padding: 0 col(2, 12);
  }
}

.g-faq__list {
  @extend %list-nostyle;

  @include mq(m) {
    padding: 0 col(2, 18);
  }

  @include mq(xl) {
    padding: 0 col(2.75, 16);
  }
}

.g-faq__item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $spacing * 1.6 col(2, 18);
  border: 1px solid $c-black-40;
  border-radius: 2rem;
  transform: translateX(0);

  & + & {
    margin-top: $spacing;
  }

  @include mq(m) {
    padding: $spacing * 1.6 col(1, 14);
  }

  @include mq(xl) {
    padding: $spacing * 1.6 col(0.5, 10.5);
  }
}

.g-faq__item__heading {
  position: relative;
}

.g-faq__item__headline {
  color: $c-blue-60;
}

.g-faq__item__title {
  padding-right: 20%;
  cursor: pointer;

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
    z-index: -1;
    bottom: initial;
    height: 7rem;
  }
}

.g-faq__item__text {
  @extend %fw-normal;

  overflow: hidden;
  max-width: 35em;
  height: 0;
  padding: 0 $spacing * 1.5 0 0;
  color: $c-black-80;
  opacity: 0;
  transition: opacity 0.8s $ease-out-expo;

  .is-open & {
    opacity: 1;
    transition: opacity 0.8s 0.1s $ease-out-expo;
  }

  ::v-deep > * {
    padding-top: $spacing * 0.6;
  }

  @include mq(l) {
    padding-right: $spacing * 3;
  }
}

.g-faq__item__button {
  @include center-y;

  right: 0;

  &[class*='action--'][class*='--btn'] {
    position: absolute;
  }
}
