
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































.agenda-card {
  position: relative;
  z-index: 1;

  &::before {
    @include center-xy;

    content: '';
    z-index: -1;
    box-sizing: content-box;
    width: 108%;
    height: 100%;
    background: $c-white;
    border-radius: 1.4rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    opacity: 0;
    transition: 0.4s ease-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  @include mq(xl) {
    padding: 0 col(1, 18);

    &::before {
      width: 100%;
    }
  }
}

.agendar-card-inner {
  display: flex;
  align-items: center;
  padding: $spacing * 1.5 0;
  border-color: rgba($c-black-40, 0);
  transition: border-color 0.2s ease-out;

  li + li & {
    border-top: 2px solid $c-black-40;
  }

  li:last-child & {
    border-bottom: 2px solid $c-black-40;
  }

  li:hover + li & {
    border-top-color: transparent;
  }

  .agenda-card:hover & {
    border-color: transparent;
  }

  @include mq(l) {
    padding-right: col(2, 18);
  }

  @include mq(xl) {
    padding: $spacing * 3.2 col(2, 16) $spacing * 3.2 0;
  }
}

.agenda-card__picture {
  position: relative;
  overflow: hidden;
  width: col(5, 18);
  height: 0;
  margin-right: col(1, 18);
  padding-bottom: 20%;
  border-radius: 4px;

  img {
    @include image-fit;
  }

  @include mq(s) {
    width: col(4, 18);
    padding-bottom: 15%;
  }

  @include mq(l) {
    width: col(4, 16);
    margin-right: col(1, 16);
    border-radius: 8px;
  }

  @include mq(xl) {
    width: col(3, 14);
    margin-right: col(1, 14);
    padding-bottom: 15%;
  }
}

.agenda-card__content {
  flex: 1;
  padding-right: 10%;
}

.agenda-card__category__full {
  @extend %fw-normal;

  display: inline-block;
  margin-left: $spacing;
  padding: $spacing * 0.05 $spacing * 0.7;
  color: $c-black-80;
  line-height: 2.8rem;
  background: $c-black-40;
  border-radius: 5rem;
}

.agenda-card__title {
  margin: $spacing * 0.25 0;

  @include mq(l) {
    margin: $spacing * 0.6 0;
  }
}

.agenda-card__address {
  @include mq($until: l) {
    @include visually-hidden;
  }
}

.agenda-card__date {
  line-height: calc(0.8rem + 1em);

  ::v-deep span {
    display: inline-block;
    margin-right: 0.2em;
  }

  @include mq(l) {
    @include center-y;

    right: 0;
    width: col(2, 18);
    color: $c-black;
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;

    ::v-deep span {
      display: block;
      margin-right: 0;
      font-size: 3.4rem;
    }
  }

  @include mq(xl) {
    right: col(1, 18);
  }
}

.agenda-card__link {
  @include get-all-space;

  z-index: 1;
}

.agenda-card__link__label {
  @extend %visually-hidden;
}
