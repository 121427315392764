
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  > header + div {
    flex: 1;
  }
}
