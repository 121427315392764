
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































.g-categories-list {
  @extend %list-nostyle;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: $spacing * 1.3 col(1);
  background: $c-white;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);

  @include mq(l) {
    padding: $spacing * 1.3 $spacing * 2.4;
  }
}

.g-categories-list__item__inner {
  @extend %button-nostyle;
  @extend %text-center;

  position: relative;
  z-index: 1;
  padding: $spacing * 0.7 $spacing * 2 $spacing * 0.6;
  line-height: 1.5;
  transition: color 0.4s ease-out;

  &::after {
    @include get-all-space;

    content: '';
    z-index: -1;
    background: $c-blue-10;
    border-radius: 6rem;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.4s ease-out, transform 0.2s ease-out;
  }

  &:hover,
  &:focus {
    color: $c-blue-80;

    &::after {
      opacity: 0.5;
      transform: scale(1);
    }
  }

  &.is-selected,
  &:focus {
    color: $c-blue-80;

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}
