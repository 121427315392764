
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































/* stylelint-disable property-no-vendor-prefix */

.form-group {
  position: relative;
  flex-basis: 100%;

  &.form-small {
    flex: 15rem 1 1;
  }

  @include mq(xs) {
    &.form-big {
      flex: calc(100% - 19rem) 1 1;
    }
  }

  @include mq(s) {
    flex: 1;

    &.form-full {
      flex-basis: 100%;
    }
  }

  @include mq(l) {
    &.form-small {
      flex-basis: 20%;
    }

    &.form-big {
      flex-basis: calc(80% - 4rem);
    }
  }
}

.form-input {
  width: 100%;
  padding: 2rem 3.6rem 1.6rem;
  color: $c-black-80;
  background: $c-black-10;
  border: 3px solid $c-black-10;
  border-radius: 6rem;
  transition: border-color 0.2s;
  -moz-appearance: textfield;

  &::-webkit-search-cancel-button {
    opacity: 0;
    -webkit-appearance: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    border-color: $c-black-40;
  }

  &:focus {
    border-color: $c-black-60;
  }

  &[aria-invalid] {
    border-color: $c-red;
  }

  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    // stylelint-disable declaration-no-important
    color: $c-black-80 !important;
    -webkit-text-fill-color: $c-black-80 !important;
    background-color: $c-black-10 !important;
    // stylelint-enable declaration-no-important
    -webkit-box-shadow: 0 0 0 1000px $c-black-10 inset;
    box-shadow: 0 0 0 1000px $c-black-10 inset;
    transition: background-color 5000s ease-in-out 0s, border-color 0.2s;
  }
}

.form-label {
  @extend %visually-hidden;
}

.form-feedback {
  position: absolute;
  bottom: -1rem;
  white-space: nowrap;
  opacity: 0;

  @include mq(m) {
    bottom: -3rem;
    opacity: 1;
  }
}
