
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































.form-switcher {
  position: relative;
  z-index: 1;
  display: inline-flex;
  width: 32rem;
  padding: $spacing * 0.25;
  background: $c-black-20;
  border-radius: 6rem;
}

.form-switcher__left,
.form-switcher__right {
  display: flex;
  align-items: center;
  flex: 1;
}

.form-switcher__input {
  @extend %visually-hidden;
}

.form-switcher__label {
  @extend %text-center;

  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: $spacing * 0.5 $spacing;
  color: $c-black-80;
  cursor: pointer;

  .form-switcher__right & {
    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $c-white;
      border-radius: 6rem;
      transform: translateX(-100%);
      transition: 0.2s ease-out;
    }
  }

  .form-switcher__input:checked + & {
    &::after {
      transform: translateX(0);
    }
  }
}
