@font-face {
  font-family: gotham;
  src: url('./../assets/fonts/gotham/gotham-thin.woff2') format('woff2');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: gotham;
  src: url('./../assets/fonts/gotham/gotham-regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: gotham;
  src: url('./../assets/fonts/gotham/gotham-medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: gotham;
  src: url('./../assets/fonts/gotham/gotham-bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

html {
  font-size: calc(1em * 0.625);
}

body {
  color: $c-body;
  font-family: $ff-default;
  font-size: $fs-body;
  font-weight: 400;
  line-height: 1.65;

  @include mq(m) {
    font-size: $fs-body-m;
  }
}
