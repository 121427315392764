
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































$card-spacing-mobile: $spacing * 1.4;
$card-spacing-desktop: $spacing * 3;

.g-related,
[class*='g-related--'] {
  background: $c-black-10;
}

.g-related__title {
  .g-related--agenda & {
    @include mq(xl) {
      padding: 0 col(1, 16);
    }
  }
}

.g-related__list {
  padding: 0;
  list-style-type: none;

  @include mq(m) {
    display: flex;

    .g-related--agenda & {
      display: block;
    }
  }
}

.g-related__item {
  .card {
    width: 100%;
    height: 100%;
  }

  & + & {
    margin-top: $card-spacing-mobile;

    .g-related--agenda & {
      margin: 0;
    }
  }

  @include mq(l) {
    flex-basis: calc(calc(100% - #{$card-spacing-desktop * 2}) / 3);

    & + & {
      margin: 0 0 0 $card-spacing-desktop;
    }
  }

  @include mq(xl) {
    ::v-deep {
      .agenda-card {
        padding: 0 col(1, 16);
      }
    }
  }
}

.g-related__cta {
  @extend %text-center;
}
