
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































































































































































































































































































































































.transition-manager {
  display: flex;
  flex-direction: column;

  > div:first-child {
    flex: 1;
  }

  // Makes the page all squished when transitioning from /a-propos/equipe to
  // before page (back button) eg: /temoignages
  // &.entering [class*='transition-enter'] {
  //   @extend %visually-hidden;
  // }
}

.transition__curtain {
  @include get-all-space;

  position: fixed;
  z-index: 1001;
  display: block;
  background: $c-blue-100;
  transform: scaleY(0);
  transform-origin: bottom;

  .none & {
    display: none;
  }

  .middle & {
    transform-origin: top;
  }

  .onboard-ready & {
    display: block;
    transform: none;
  }
}

.transition__curtain-content {
  @include center-xy;

  display: none;

  .onboard-ready & {
    display: block;
  }
}

.transition__curtain__logo {
  width: 23rem;
  margin: 0 auto $spacing * 2.5;
  opacity: 0;
  fill: $c-white;
}

.transition__curtain__list {
  @extend %list-nostyle;
  @extend %text-center;

  color: $c-white;

  @include mq(m) {
    display: flex;
  }
}

.transition__curtain__item {
  @extend %fw-normal;

  font-size: 2.4rem;
  opacity: 0;

  & + & {
    margin-top: $spacing;
  }

  @include mq(m) {
    & + & {
      margin: 0 0 0 $spacing * 1.5;
    }
  }
}
