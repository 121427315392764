
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































.g-duo,
[class*='g-duo--'] {
  @include fluid(
    margin-top,
    (
      xxs: 30px,
      xl: 90px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      xxs: 70px,
      xl: 180px,
    )
  );

  @include mq(l) {
    display: flex;
  }
}

.g-duo__picture {
  flex: 1;

  @include mq(l) {
    height: auto;
    padding-bottom: 0;
  }
}

.g-duo__content {
  @extend %text-center;
  @include fluid(
    padding-top,
    (
      s: 54px,
      xl: 170px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      s: 52px,
      xl: 130px,
    )
  );

  margin-top: $spacing;
  padding-right: col(2, 18);
  padding-left: col(2, 18);
  background: $c-black-10;
  border-radius: 2.4rem;

  @include mq(l) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 50% 0 1;
    margin: 0 0 0 $spacing * 1.7;
    padding-right: col(1, 18);
    padding-left: col(1, 18);
    text-align: left;
    border-radius: 4rem;
  }
}

.g-duo__content__title {
  @extend %fw-normal;

  @include mq(l) {
    max-width: 7em;
  }
}

.g-duo__content__text {
  letter-spacing: 0.5px;

  @include mq(l) {
    max-width: 24em;
  }
}
