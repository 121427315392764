
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































.about-percentages__list {
  @extend %list-nostyle;
}

.about-percentage {
  display: flex;
  align-items: baseline;

  & + & {
    margin-top: $spacing * 0.8;
  }

  .chart & {
    position: relative;
    z-index: 1;
    justify-content: space-between;
    max-width: 50rem;
    padding: $spacing $spacing * 1.5;

    & + & {
      margin-top: $spacing * 0.5;
    }
  }
}

.about-percentage__value {
  flex: 5rem 0 0;
  color: $c-blue-80;
}

.about-percentage__separator {
  flex: 14.4rem 0 0;
  height: 0.3rem;
  // eslint-disable-next-line max-len
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='6' width='18'%3E%3Ccircle cx='3' cy='3' r='3' fill='%23B4DFFF' /%3E%3C/svg%3E%0A");
  background-size: 9px 3px;

  .chart & {
    @include get-all-space;

    z-index: -1;
    overflow: hidden;
    background: $c-blue-10;
    background-image: none;
    border-radius: 6rem;

    &::before {
      content: '';
      position: absolute;
      width: var(--percentage);
      height: 100%;
      background: $c-blue-20;
    }
  }
}

.about-percentage__label {
  overflow: hidden;
  margin-left: $spacing * 0.4;
  text-overflow: ellipsis;
  white-space: nowrap;
}
