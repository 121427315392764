
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































.flexible-video,
[class*='flexible-video--'] {
  @include mq(l) {
    margin-right: col(-1, 10);
    margin-left: col(-1, 10);
  }
}

.flexible-video__picture {
  position: relative;
}

.flexible-video__button {
  @include get-all-space;

  ::v-deep .button-magnetic {
    top: calc(50% - 3rem);
    left: calc(50% - 3rem);
  }
}
