//Avoid typekit FOUT
html {
  scroll-behavior: auto;

  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

body {
  color: $c-blue;
  background: $c-white;
}

// SVG sprite
body > svg {
  display: none;
}

.icon {
  fill: currentColor;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// NO SCROLL
.no-scroll,
.popup-active {
  overflow-y: hidden;
  height: 100vh;

  @media (hover: hover) {
    padding-right: 15px;
  }
}
// VARIOUS
hr {
  height: 1px;
  background-color: $c-gray-darker;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

.g-picture,
[class*='g-picture--'] {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  // border-radius: 2.4rem;

  img {
    @include image-fit();
  }

  @include mq(m) {
    padding-bottom: 56.25%;
  }

  // @include mq(l) {
  //   // border-radius: 4rem;
  // }
}

// Remove visible focus
*:focus:not(:focus-visible) {
  outline: none;
}

// Recaptcha
.grecaptcha-badge {
  visibility: hidden;
}
