
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































$scrollbar-width: 15px;

.g-overlayer {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
  top: 0;
  overflow-y: hidden;
  width: 100%;
  visibility: hidden;
  pointer-events: none;

  &.is-visible {
    overflow-y: scroll;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.g-overlayer-wrapper {
  // padding-right: calc(5% - #{$scrollbar-width});

  @include mq($until: m) {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(wrapper) {
    padding-right: calc(5% - #{$scrollbar-width});
    padding-left: 5%;
  }
}

.g-overlayer__inner {
  position: relative;
  min-height: 100vh;
  padding: $spacing * 1.5 col(1, 18);
  background: $c-white;
  border-radius: 4rem;

  @include mq(l) {
    margin-right: col(-0.5, 18);
    margin-left: col(-0.5, 18);
    padding: $spacing * 1.5 col(1.5, 18);

    .button-close {
      position: relative;
      top: 0;
      right: 0;
      margin-right: col(-1, 18);
    }
  }

  @include mq(wrapper) {
    padding: $spacing * 3 col(2.5, 18);

    .button-close {
      margin-right: col(-2, 18);
    }
  }
}

.g-overlayer__button {
  @extend %button-nostyle;

  display: block;
  margin: 0 0 $spacing * 2.5 auto;

  @include mq(l) {
    position: relative;
    top: 0;
    right: 0;
    margin-right: col(-1, 16);
  }

  @include mq(wrapper) {
    margin-right: col(-2.5, 18);
  }
}
