.form-outer {
  position: relative;
}

.form-error,
.form-success {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  font-size: 0.7em;
  font-style: italic;
  line-height: 2;
  text-align: right;
}

.form-error {
  color: $c-dev-error;
}

.form-success {
  color: $c-dev-success;
}
