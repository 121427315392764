
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























.g-lead,
[class*='g-lead--'] {
  @extend %text-center;

  margin-bottom: $spacing * 2.5;
}
