
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































































.g-popup-outer {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  padding: $spacing col(1, 20);
  background: rgba($c-blue-100, 0.8);
}

.g-popup {
  position: relative;
}

.g-popup__button {
  @extend %button-nostyle;

  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 1rem;

  .flexible-video__popup & {
    position: fixed;
    top: 2rem;
    right: 3rem;
  }
}
