/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * naming: if no idea, check http://chir.ag/projects/name-that-color/
 * order: from dark to light
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: #b2b2b2;
$c-gray-light: #d8d8d8;
$c-gray-lighter: #e6e6e6;
$c-gray-lightest: #f5f5f5;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;
$c-green: #17b603;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-body: $c-gray-darkest;

$c-black: #2b2b2b;
$c-black-100: $c-black;
$c-black-80: #5e5e5e;
$c-black-70: #a3a3a3;
$c-black-60: #dbdbdb;
$c-black-40: #e9e9e9;
$c-black-20: #f2f2f2;
$c-black-10: #f8f8f8;
$c-black-navigation: #646464;
$c-black-true: #000;

$c-blue: #43005a;
$c-blue-100: $c-blue;
$c-blue-80: #710097;
$c-blue-60: #a730dc;
$c-blue-50: #bb7ede;
$c-blue-40: #ecdcf0;
$c-blue-30: #ecdcf0;
$c-blue-20: #ecdcf0;
$c-blue-10: #ecdcf0;
$c-blue-logo: $c-blue-60;

$c-green: #710097;
$c-green-100: $c-green;
$c-green-80: #a730dc;

$c-gradient-lightest: $c-blue-20;
$c-gradient-light: #a42dd8;
$c-gradient-dark: #720198;
$c-gradient-card: #002b4a;

// Gradients
$gradient-typeface: linear-gradient($c-gradient-light, $c-gradient-dark);
$gradient-card: linear-gradient(
  to top,
  $c-gradient-card,
  rgba($c-gradient-card, 0)
);

/*
* Dimensions
*/
$content-width-max: mq-get-breakpoint-width(wrapper);
$header-height: 60px;
$header-breakpoint: xl;

/*
* Typography
*/

$ff-default: gotham, sans-serif;

$fs-base: 10px !default;
$fs-body: 1.5rem;
$fs-body-m: 1.8rem;

/**
* RWD
*/
$spacing: 2rem;

$mq-responsive: true;
$mq-static-breakpoint: 'large';
