
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























.flexible-quote,
[class*='flexible-quote--'] {
  @extend %fw-normal;

  position: relative;
  padding-left: $spacing * 2;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0.6rem;
    height: 100%;
    background: $c-blue-80;
    border-radius: 0.4rem;
  }
}
