
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































.flexible-prices__title {
  @include fluid(
    margin-bottom,
    (
      xxs: 20px,
      l: 67px,
    )
  );

  text-align: center;
}

.flexible-prices__list {
  @extend %list-nostyle;

  padding: 0 $spacing * 2.5;
  color: $c-white;
  background: $c-blue-100;
  border-radius: 2rem;

  @include mq(s) {
    display: flex;
    padding: $spacing * 2.5 0;
  }
}

.flexible-prices__item {
  @extend %text-center;

  padding: $spacing * 2.2 0;

  & + & {
    border-top: 2px solid rgba($c-white, 0.2);
  }

  @include mq(s) {
    flex: 1;
    padding: 0 $spacing;

    & + & {
      border-top: 0;
      border-left: 2px solid rgba($c-white, 0.2);
    }
  }
}

.flexible-prices__item__headline {
  margin-bottom: $spacing * 1.25;
  color: $c-blue-40;

  @include mq(s) {
    margin-top: 0;
  }
}

.flexible-prices__item__price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing * 1.25;

  @include mq(l) {
    align-items: baseline;
  }
}

.flexible-prices__item__price__euro {
  @extend %fw-normal;

  position: relative;
  color: rgba($c-white, 0.4);
  font-size: 1.6rem;
  line-height: 1;
  text-align: left;

  span {
    display: inline-block;
    color: $c-white;
  }

  @include mq(l) {
    margin-left: $spacing * 0.5;

    span {
      position: absolute;
      top: -3rem;
      left: 0;
      color: $c-white;
    }
  }
}

.flexible-prices__item__text {
  margin-top: $spacing;
  color: $c-white;

  @include mq(s) {
    margin-top: $spacing * 1.5;
  }
}
